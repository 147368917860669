import React, {useContext, useEffect, useState} from "react";
import {Button, Dialog, DialogBody, DialogFooter, FormGroup, HTMLSelect, Label, NumericInput, Tooltip} from "@blueprintjs/core";
import PropTypes from "prop-types";
import {AppConfigContext} from "../../context/AppConfigContextProvider";
import {SelectedOrganizationContext} from "../../context/SelectedOrganizationContextProvider";
import OTADialog from "./OTADialog";

export default function BaseAppParamDialog(
    {
        appParams, device, deviceMutation, isDialogOpen, closeDialog, initialSettings = {}, confirmedSettings = {},
        extraButtons, nodeFirmware, neuralNetwork, wisunFirmware, gatewayScript
    }
) {

    // Initializing the current OTA values
    let otas = {}

    // Assign the corresponding OTA Fields to specific device
    if(device.deviceType === 'Sensor'){
        otas = { 
            neuralNetworkId: device.json.neuralNetworkId, 
            nodeFirmwareId: device.json.nodeFirmwareId, 
            wisunFirmwareId: device.json.wisunFirmwareId 
        }
    } else if(device.deviceType === 'Gateway'){
        otas = { 
            gatewayScriptId: device.json.gatewayScriptId,  
            wisunFirmwareId: device.json.wisunFirmwareId 
        }
    }

    const [ otaValue, setOtaValue ] = useState(otas)

    const [updatedSettings, setUpdatedSettings] = useState(initialSettings);
    const [initialValue, setInitialValue] = useState(initialSettings);

    const debug = false;

    const {organization} = useContext(SelectedOrganizationContext);

    const devAdmin = organization.hasRole("admin-dev");
    const availableParams = appParams.filter(param => devAdmin || param.userEditable);

    // Submit form changes
    function save(){
        let otaChanges = {}
        for(const [key, val] of Object.entries(otaValue)){
            if (otas && val !== otas[key]){
                // If 'No Updates' (value 0) => clear Sensor/Gateway portion of OTA ID
                if (val === 0){
                    otaChanges[key]= null
                } else{
                    otaChanges[key]= val
                }
            }
        }

        deviceMutation.mutate({
            deviceId: device.id,
            action: "update",
            appParams: updatedSettings,
            otaFiles: otaChanges
        });
        setInitialValue(updatedSettings);
        closeDialog();
    }

    function setParamValue(param, value){
        setUpdatedSettings({
            ...updatedSettings,
            [param.name]: value
        });
    }

    function cancel(){
        // Reset all the values
        setOtaValue(otas)
        setUpdatedSettings(initialSettings);
        closeDialog();
    }

    function reset() {
        const resets = availableParams.reduce((acc, value) => {
            if (value.name && value.valueAttributes.default_value){
                acc[value.name] = getDefaultValue(value);
            }
            return acc
        }, {});
        setUpdatedSettings(resets);
    }

    function isDefaultValue(){
        let defaultParams = true;
        availableParams.map(param => {
            if(getParamValue(param) !== param.valueAttributes.default_value){
                defaultParams = false;
            }
        });
        return defaultParams;
    }

    function isInitialValue(){
        const initialParams = false;
        return initialParams;
    }

    function getDefaultValue(param){
        return param.valueAttributes.default_value;
    }

    function getParamValue(param){
        if (updatedSettings[param.name] !== undefined) {
            return updatedSettings[param.name];
        }
        return getDefaultValue(param);
    }

    function getParamConfirmedValue(param){
        return confirmedSettings[param.name] || getDefaultValue(param);
    }
    
    function getInvalidReason(param){
        const value = getParamValue(param);
        const min = getParamMin(param);
        const max = getParamMax(param);
        if(param.valueType === 'FLOAT' || param.valueType === 'INTEGER'){
            if(value < min){
                return `Value must be greater than or equal to ${min}`;
            }
            if(value > max){
                return `Value must be less than or equal to ${max}`;
            }
        }
        return null;
    }

    function getDescriptiveConfirmedValue(param){
        const value = getParamConfirmedValue(param);
        if (param.valueType === 'ENUM') {
            const enumValue = param.enumValues.find(v => v.value === value);
            return enumValue ? enumValue.name : value;
        }
        return value;
    }
    function getHelperText(param){
        return <span>
            { param.description }
            {param.valueType === 'FLOAT' && <p> <b>Range</b>: {getParamMin(param)} - {getParamMax(param)}</p>}
            {param.valueType === 'INTEGER' && <p> <b>Range</b>: {getParamMin(param)} - {getParamMax(param)}</p>}

            { getParamValue(param) != getParamConfirmedValue(param) && <p><b>Warning the current value on the device is {getDescriptiveConfirmedValue(param)}. Target value is {getParamValue(param)}</b></p>}
            { !isValid(param) &&  <p><b>{getInvalidReason(param)}</b></p>}
        </span>;
    }

    function getLabelInfo(param){
        return <span>
            {param.valueType === 'FLOAT' && <span> (Float) </span>}
            {param.valueType === 'INTEGER' && <span> (Integer) </span>}
            {param.valueType === 'ENUM' && <span> (Enum)</span>}
        </span>;
    }

    function getParamMax(param){
        if(devAdmin && param.valueAttributes.firmware_max !== undefined){
            return param.valueAttributes.firmware_max;
        } else {
            return param.valueAttributes.max_value;
        }
    }

    function getParamMin(param){
        if(devAdmin && param.valueAttributes.firmware_min !== undefined) {
            return param.valueAttributes.firmware_min;
        } else {
            return param.valueAttributes.min_value;
        }
    }

    function getStepSize(param){
        if(param.valueAttributes.step_size !== undefined){
            return param.valueAttributes.step_size;
        }
        if(param.valueType === 'FLOAT'){
            return 0.01;
        }
        return 1;
    }

    function getMinorStepSize(param){
        if(param.valueAttributes.minor_step_size !== undefined){
            return param.valueAttributes.minor_step_size;
        }
        return getStepSize(param);
    }

    function getMajorStepSize(param){
        if(param.valueAttributes.major_step_size !== undefined){
            return param.valueAttributes.major_step_size;
        }
        return getStepSize(param) * 10;
    }

    function isValid(param){
        const value = getParamValue(param);
        const min = getParamMin(param);
        const max = getParamMax(param);

        if(param.valueType === 'FLOAT' || param.valueType === 'INTEGER'){
            return (value >= min && value <= max);
        }

        return true;
    }

    function isChanged(params) {
        //Check if there is any change in the value compare to initial value
        let change = false;
        params.map(param => {
            if(initialValue[param.name] === undefined){
                if(getParamValue(param) !== getDefaultValue(param)){
                    change = true;
                }
            }else{
                if(getParamValue(param) !== initialValue[param.name]){
                    change = true;
                }
            }
        });

        return change;
    }

    // Check if the form has any changes and if the form values are valid (with 'isValid()')
    function isFormValid(){
        const changed = isChanged(availableParams)

        let otaChanged = false 
        for(const [key, val] of Object.entries(otaValue)){
            if(otas && val !== otas[key]){
                otaChanged = true
            }
        }

        return availableParams.every(isValid) && changed || otaChanged;
    }

    // Handle OTA Values changes in the form
    function handleOTAChange(newVal){
        setOtaValue(prevVal => ({ ...prevVal, [newVal.target.name]: Number(newVal.target.value) }))
    }

    return <Dialog title="App Params" icon="info-sign" isOpen={isDialogOpen} onClose={cancel}>
        <DialogBody>
            <OTADialog  
                nodeFirmware={nodeFirmware}
                neuralNetwork={neuralNetwork}
                wisunFirmware={wisunFirmware}
                gatewayScript={gatewayScript}
                currentOTA={otaValue}
                onOTAChange={handleOTAChange}
            />
            {
                availableParams.map((param, index) =>
                    <>
                    <Tooltip key={`${param.id}-shortDescription`} content={param.shortDescription} position='top'> 
                        <Label htmlFor={`input-${param.id}`}>
                            {param.name}
                            <span className="bp5-text-muted">{getLabelInfo(param)}</span>
                        </Label>
                    </Tooltip>
                    <FormGroup
                        key={param.id}
                        helperText={getHelperText(param)}
                        className={isValid(param) ? "" : "form-invalid"}
                    >
                        {   (param.valueType === 'FLOAT' || param.valueType === 'INTEGER') &&
                            <NumericInput
                                id={`input-${param.id}`}
                                placeholder=""
                                value={getParamValue(param)}
                                onValueChange={(value, s) => setParamValue(param, Number(s))}
                                min={getParamMin(param)}
                                max={getParamMax(param)}
                                stepSize={getStepSize(param)}
                                minorStepSize={getMinorStepSize(param)}
                                majorStepSize={getMajorStepSize(param)}
                            />
                        }
                        {param.valueType === 'ENUM' &&
                            <HTMLSelect
                                id={`input-${param.id}`}
                                onChange={evt => setParamValue(param, parseInt(evt.target.value))}
                                value={`${getParamValue(param)}`}
                                fill={true}
                                options={param.enumValues.map(v => ({
                                    value: `${v.value}`,
                                    label: `${v.name}`
                                }))}
                            />
                        }
                    </FormGroup>
                    </>
                )
            }

            {
                debug && <>
                <pre>{JSON.stringify(updatedSettings, null, 2)}</pre>
                </>
            }


        </DialogBody>
        <DialogFooter actions={<>
            {extraButtons}
            {devAdmin ? <Button disabled={isDefaultValue()} intent="none" text="Set to Default" onClick={reset} /> : null}
            <Button intent="none" text="Cancel" onClick={cancel} />
            <Button intent="primary" text="Save" disabled={!isFormValid()} onClick={() => save()} />
            </>}
        />
    </Dialog>
}

BaseAppParamDialog.propTypes = {
    appParams: PropTypes.array,
    device: PropTypes.object,
    deviceMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    initialSettings: PropTypes.object,
    confirmedSettings: PropTypes.object,
    extraButtons: PropTypes.element,
    nodeFirmware: PropTypes.array,
    neuralNetwork: PropTypes.array,
    wisunFirmware: PropTypes.array,
    gatewayScript: PropTypes.array,
}
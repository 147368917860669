import React from "react"
import PropTypes from "prop-types";
import { HTMLSelect, Label, FormGroup } from "@blueprintjs/core"

// OTA Files configurations located inside App Params (both Sensor and Gateway)
export default function OTADialog (props) {
    // Function to extract the name of the OTA files
    function otaNameExtration(obj){
        let regex;
        if(obj.type === 'NEURAL_NETWORK'){
            regex = /.*nns\/(\S+)$/;
        } else if(obj.type === 'NODE_FIRMWARE'){
            regex = /.*palladium-fws\/(\S+)$/;
        } else if (obj.type === 'WISUN_FIRMWARE'){
            regex = /.*wisun_fws\/(\S+)$/
        } else if (obj.type === 'GATEWAY_SCRIPT'){
            regex = /.*gateway-scripts\/(\S+)$/
        }

        const match = obj.url.match(regex);

        if(match){
            // obj['name'] = match[1]
            return match[1]
        } else{
            // obj['name'] = obj.url
            return obj.url
        }
    }

    return<>
        {props.nodeFirmware ? 
            <FormGroup style={{marginBottom:'1rem'}}>
                <Label htmlFor={`input-ota-node-fw`}>
                    Node Firmware
                </Label>
                <HTMLSelect
                    id={`input-ota-node-fw`}
                    name='nodeFirmwareId'
                    value={props.currentOTA.nodeFirmwareId || 0}
                    fill={true}
                    options={[
                        ...props.nodeFirmware.map(file => ({
                            value: `${file.id}`,
                            label: `${otaNameExtration(file)}`,
                        })),
                        { value: 0, label: 'No Updates' }
                    ]}
                    onChange={props.onOTAChange}
                />
            </FormGroup>
        : null}
        {props.neuralNetwork ? 
            <FormGroup style={{marginBottom:'1rem'}}>
                <Label htmlFor={`input-ota-neural-network`}>
                    Neural Network
                </Label>
                <HTMLSelect
                    id={`input-ota-neural-network`}
                    name='neuralNetworkId'
                    value={props.currentOTA.neuralNetworkId || 0}
                    fill={true}
                    options={[...props.neuralNetwork.map(file => ({
                        value: `${file.id}`,
                        label: `${otaNameExtration(file)}`
                        })),
                        { value: 0, label: 'No Updates' }
                    ]}
                    onChange={props.onOTAChange}
                />
            </FormGroup>        
        : null}
        {props.wisunFirmware ?
            <FormGroup style={{marginBottom:'1rem'}}>
                <Label htmlFor={`input-ota-wisun-fw`}>
                    Wisun Firmware
                </Label>
                <HTMLSelect
                    id={`input-ota-wisun-fw`}
                    name='wisunFirmwareId'
                    value={props.currentOTA.wisunFirmwareId || 0}
                    fill={true}
                    options={[...props.wisunFirmware.map(file => ({
                        value: `${file.id}`,
                        label: `${otaNameExtration(file)}`
                        })),                         
                        { value: 0, label: 'No Updates' }
                    ]}
                    onChange={props.onOTAChange}
                />
            </FormGroup>             
        : null}
        {props.gatewayScript ? 
            <FormGroup style={{marginBottom:'1rem'}}>
                <Label htmlFor={`input-ota-gateway-script`}>
                    Gateway Script
                </Label>
                <HTMLSelect
                    id={`input-ota-gateway-script`}
                    name='gatewayScriptId'
                    value={props.currentOTA.gatewayScriptId || 0}
                    fill={true}
                    options={[...props.gatewayScript.map(file => ({
                        value: `${file.id}`,
                        label: `${otaNameExtration(file)}`
                        })),                         
                        { value: 0, label: 'No Updates' }
                    ]}
                    onChange={props.onOTAChange}
                />
            </FormGroup>   
        : null}
    </>
}

OTADialog.propTypes = {
    nodeFirmware: PropTypes.array,
    neuralNetwork: PropTypes.array,
    wisunFirmware: PropTypes.array,
    gatewayScript: PropTypes.array,
    currentOTA: PropTypes.object,
    onOTAChange: PropTypes.func
}
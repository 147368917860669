import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {AppConfigContext} from "../../context/AppConfigContextProvider";
import BaseAppParamDialog from "./BaseAppParamDialog";
import {Button} from "@blueprintjs/core";

export default function AppParamDialog(
    {sensor, sensorMutation, isDialogOpen, closeDialog, initialSettings = {}, confirmedSettings = {}}
) {

    const {appParams, OTAFiles} = useContext(AppConfigContext);

    const nodeFirmware = OTAFiles.filter( ele => ele.type === 'NODE_FIRMWARE')
    const neuralNetwork = OTAFiles.filter( ele => ele.type === 'NEURAL_NETWORK')
    const wisunFirmware = OTAFiles.filter( ele => ele.type === 'WISUN_FIRMWARE')

    return <BaseAppParamDialog
        appParams={appParams}
        device={sensor}
        deviceMutation={sensorMutation}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        initialSettings={initialSettings}
        confirmedSettings={confirmedSettings}
        extraButtons={undefined}
        nodeFirmware={nodeFirmware}
        neuralNetwork={neuralNetwork}
        wisunFirmware={wisunFirmware}
        gatewayScript={null}
    />
}

AppParamDialog.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    initialSettings: PropTypes.object,
    confirmedSettings: PropTypes.object
}